<template>
  <v-data-table
    disable-filtering
    disable-pagination
    fixed-header
    :headers="tableHeaders"
    hide-default-footer
    item-key="id"
    :items="items"
    must-sort
    :server-items-length="serverItemsLength"
    :show-select="false"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    width="100%"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <!-- it was difficult to make the highlight class work in the <tr>,
           so instead they appear many times in the <td>s -->
      <tr>
        <template v-if="groupingType !== 'security'">
          <td :class="getHighlightClass(item.id)">
            {{ item.security.ticker }}
          </td>
          <td :class="getHighlightClass(item.id)">
            {{ item.security.cusip }}
          </td>
        </template>
        <template v-if="groupingType !== 'counterparty'">
          <td :class="getHighlightClass(item.id)">
            {{ item.counterparty.companyName }} ({{ item.counterparty.displayBoxId }})
          </td>
        </template>
        <td class="text-end" :class="getHighlightClass(item.id)">
          <rate-output :rate="item.rate" />
        </td>
        <td :class="getHighlightClass(item.id)">
          <div class="d-flex text-start">
            <div
              class="pa-2x single-loan-input"
              data-test="single-loan-input"
              @click="$emit('click-single-quantity')"
            >
              <numeric-input
                class="loan-quantity"
                data-test="loan-quantity"
                dense
                :disabled="isReadOnly"
                :error-messages="newQuantities[item.id] > availableToUpdate(item) ? 'exceeded' : ''"
                :min="0"
                outlined
                placeholder="0"
                small
                :step="100"
                type="integer"
                :value="newQuantities[item.id] || 0"
                @input="$emit('update-single-quantity', { loan: item, quantity: $event })"
              />
            </div>
            <v-btn
              class="pa-0 max-btn"
              :class="{
                'fade-max-icon':
                  availableToUpdate(item) !== newQuantities[item.id] ||
                  availableToUpdate(item) === 0,
              }"
              :disabled="isReadOnly"
              outlined
              x-small
              @click="
                $emit('click-single-quantity');
                $emit('update-single-quantity', {
                  loan: item,
                  quantity: availableToUpdate(item),
                });
              "
            >
              Max
            </v-btn>
          </div>
        </td>
        <td class="text-end" :class="getHighlightClass(item.id)">
          <pretty-number
            :value="action === 'recall' ? item.openQuantityToRecall : item.openQuantityToReturn"
          />
        </td>
        <td class="text-end" :class="getHighlightClass(item.id)">
          <pretty-number :value="item.openQuantity" />
        </td>
        <td class="text-end" :class="getHighlightClass(item.id)">
          ${{ formatPrice(item.openQuantity * item.security.lastClosePrice) }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { PRICE_PRECISION } from '@/modules/common/constants/precision';
import { NewQuantities, availableToUpdate } from '@/modules/open-loans/helpers/multipleUpdates';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { OpenLoanItem } from '@/modules/common/models';
import { getPriceAsString } from '@/utils/helpers/auction-numbers';
import Decimal from 'decimal.js';
import { PropType } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { DataTableHeader } from 'vuetify';

@Component({
  props: {
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array as PropType<OpenLoanItem[]>,
      required: true,
    },
    action: {
      type: String as PropType<MultipleLoanAction>,
      required: true,
    },
    groupingType: {
      type: String as PropType<'counterparty' | 'security' | null>,
      default: null,
    },
    newQuantities: {
      type: Object as PropType<NewQuantities>,
      required: true,
      default: () => ({}),
    },
    sortBy: {
      type: String,
      default: 'rate',
    },
    sortDesc: {
      type: Boolean,
      default: true,
    },
    serverItemsLength: Number,
  },
})
export default class MassModifyLoansEditTable extends mixins(validationMixin) {
  protected readonly isReadOnly!: boolean;
  protected readonly items!: OpenLoanItem[];
  protected readonly action!: MultipleLoanAction;
  protected readonly groupingType!: 'counterparty' | 'security';
  protected readonly newQuantities!: NewQuantities;
  protected readonly sortBy!: string;
  protected readonly sortDesc!: boolean;

  protected get tableHeaders(): DataTableHeader[] {
    const headers: DataTableHeader[] = [
      ...((this.groupingType === 'security'
        ? []
        : [
            {
              text: 'Ticker',
              value: 'security.ticker',
              align: 'start',
              width: '6rem',
              sortable: !this.isReadOnly,
            },
            {
              text: 'CUSIP',
              value: 'security.cusip',
              align: 'start',
              width: '6rem',
              sortable: !this.isReadOnly,
            },
          ]) as DataTableHeader[]),
      ...((this.groupingType === 'counterparty'
        ? []
        : [
            {
              text: 'Counterparty',
              value: 'counterparty',
              align: 'start',
              width: '14rem',
              sortable: !this.isReadOnly,
            },
          ]) as DataTableHeader[]),
    ];
    headers.push({
      text: 'Rate',
      value: 'rate',
      align: 'end',
      sortable: !this.isReadOnly,
    });
    headers.push({
      text: `${this.action === 'recall' ? 'Recall' : 'Return'} Quantity`,
      value: 'toUpdate',
      align: 'start',
      width: '12rem',
      sortable: false,
    });
    if (this.action === 'recall') {
      headers.push({
        text: 'Available To Recall',
        value: 'openQuantityToRecall',
        align: 'end',
        sortable: !this.isReadOnly,
      });
    } else {
      headers.push({
        text: 'Available To Return',
        value: 'openQuantityToReturn',
        align: 'end',
        sortable: !this.isReadOnly,
      });
    }
    headers.push({
      text: 'Open',
      value: 'openQuantity',
      align: 'end',
      sortable: !this.isReadOnly,
    });
    headers.push({
      text: 'Settlement Value',
      value: 'settlementValue',
      align: 'end',
      sortable: false,
    });

    return headers;
  }

  protected availableToUpdate(loan: OpenLoanItem): number {
    return availableToUpdate(loan);
  }

  protected getHighlightClass(id: number): string {
    return this.newQuantities[id] > 0 ? 'highlight' : '';
  }

  protected formatPrice(price: number | Decimal): string {
    return getPriceAsString(price, PRICE_PRECISION);
  }
}
</script>
<style lang="scss" scoped>
.single-loan-input {
  scale: 0.75;
  margin: 3px 0 0 -20px;
}

::v-deep {
  // so the "click" event propagates to the parent when <numeric-input> is disabled
  input[disabled] {
    pointer-events: none;
  }
}

.max-btn {
  margin-top: 13px;
  margin-right: -16px;
}

.fade-max-icon {
  opacity: 0.2;
  transition: 0.0125s;
}

.fade-max-icon:hover {
  opacity: 1;
}

.table-container tr,
.summary {
  /* Tone down grey to increase contrast between bold and non-bold text */
  color: #888;
}

.highlight,
.summary strong {
  color: white;
}

.theme--light .table-container tr,
.theme--light .summary {
  color: #777;
}

.theme--light .highlight,
.theme--light .summary strong {
  color: black;
}

::v-deep {
  .v-text-field__details {
    display: none;
  }
}
</style>
