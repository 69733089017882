<template>
  <div>
    <v-alert
      border="top"
      class="mb-10 py-6 securities-intro-alert"
      color="primary"
      colored-border
      elevation="8"
      type="info"
    >
      <span class="header">Select which Securities are eligible for trading.</span><br />
      <span class="sub">
        For a security to be active it needs to be marked as Enabled (by us) and Can Loan (by DTCC).
      </span>
    </v-alert>

    <v-container fluid>
      <v-row class="mb-5">
        <v-col>
          <!-- search bar -->
          <v-text-field
            v-model="securitySearch"
            autofocus
            clearable
            :label="$t('searchEquities')"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col>
          <!-- show enabled and/or disabled securities -->
          <v-select
            v-model="securityFilter"
            :items="securityFilters"
            :label="$t('equities.filter.label')"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      class="elevation-0 pb-8"
      dense
      fixed-header
      :footer-props="{ itemsPerPageOptions: [10] }"
      :headers="tableColumns"
      :items="securities"
      :items-per-page="10"
      :no-data-text="noDataText"
      :page.sync="pageNumber"
      :server-items-length="recordCount"
      @update:page="onPageChange"
      @update:sort-by="onSortBy"
      @update:sort-desc="onSortDesc"
    >
      <template #[`item.isActive`]="{ item }">
        <span :title="generateActiveTooltip(item)">
          <v-icon v-if="item.isActive" color="grey">mdi-checkbox-marked</v-icon>
          <v-icon v-else color="grey">mdi-checkbox-blank-off-outline</v-icon>
        </span>
      </template>

      <template #[`item.isAuroraActive`]="{ item }">
        <v-checkbox
          v-model="item.isAuroraActive"
          dense
          hide-details
          @change="onToggleEnabled(item)"
        ></v-checkbox>
      </template>

      <template #[`item.isAuroraRestricted`]="{ item }">
        <v-checkbox
          v-model="item.isAuroraRestricted"
          dense
          hide-details
          @change="onToggleRestricted(item)"
        >
        </v-checkbox>
      </template>

      <template #[`item.hasActivePriceDataSource`]="{ item }">
        <v-icon color="grey">
          {{
            item.hasActivePriceDataSource ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-off-outline'
          }}
        </v-icon>
      </template>

      <template #[`item.isCusipActive`]="{ item }">
        <v-icon color="grey"
          >{{ item.isCusipActive ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-off-outline' }}
        </v-icon>
      </template>

      <template #[`item.dtccCanNewLoan`]="{ item }">
        <v-icon color="grey">
          {{ item.dtccCanNewLoan ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-off-outline' }}
        </v-icon>
      </template>

      <template #[`item.dtccCanRoll`]="{ item }">
        <v-icon color="grey">
          {{ item.dtccCanRoll ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-off-outline' }}
        </v-icon>
      </template>

      <template #[`item.closePrice`]="{ item }">
        <price-output
          v-if="item.hasActivePriceDataSource && !item.closePrice.isZero()"
          :price="item.closePrice"
        />
        <pre v-else class="px-10">N/A</pre>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { Component, Vue, Watch } from 'vue-property-decorator';

import i18n from '@/localisation/i18n';
import PriceOutput from '@/modules/common/components/format-price/PriceOutput.vue';
import { errorString } from '@/utils/helpers/rest-response';
import { DataTableHeader } from 'vuetify';
import { mapActions } from 'vuex';
import { AuctionSecurity, RawAuctionSecurity } from '@/modules/auction/models';

const tableColumns = [
  { text: 'Active', value: 'isActive', sortable: false, width: 16 },
  { text: 'Enabled', value: 'isAuroraActive', sortable: false, width: 16 },
  { text: 'Restricted', value: 'isAuroraRestricted', sortable: false, width: 16 },
  { text: 'Can Loan', value: 'dtccCanNewLoan', sortable: false, width: 16 },
  { text: 'Can Roll', value: 'dtccCanRoll', sortable: false, width: 16 },
  { text: 'Ticker', value: 'ticker', cellClass: 'text-no-wrap text-truncate' },
  { text: 'CUSIP', value: 'cusip', cellClass: 'text-no-wrap text-truncate' },
  { text: 'Security', value: 'name', cellClass: 'text-no-wrap text-truncate' },
  {
    text: 'Last Close Price',
    value: 'closePrice',
    sqlColumn: 'last_close_price',
    cellClass: 'is-numeric',
  },
];

@Component({
  components: { PriceOutput },
  data: () => ({
    loading: false,
    securities: [],
    securityFilter: 'all',
    securityFilters: [
      { text: i18n.tc('equities.filter.all'), value: 'all' },
      { text: i18n.tc('equities.filter.active'), value: 'active' },
      { text: i18n.tc('equities.filter.inactive'), value: 'inactive' },
    ],
    securitySearch: '',
    recordCount: 0,
    sortField: 'ticker',
    sortOrder: false,
    pageNumber: 1,
  }),
  methods: {
    ...mapActions(['enableSecurity', 'disableSecurity', 'restrictSecurity', 'unrestrictSecurity']),
  },
})
export default class Securities extends Vue {
  // https://axios-http.com/docs/cancellation
  protected abortController: AbortController | null = null;
  private loading!: boolean;
  private securities!: AuctionSecurity[];
  private securityFilter!: 'all' | 'active' | 'inactive';
  private securityFilters!: Array<{ text: string; value: 'all' | 'active' | 'inactive' }>;
  private securitySearch!: string;
  private recordCount!: number;
  private sortField!: string;
  private sortDesc!: boolean;
  private pageNumber!: number;
  private enableSecurity!: (security: AuctionSecurity) => void;
  private disableSecurity!: (security: AuctionSecurity) => void;
  private restrictSecurity!: (security: AuctionSecurity) => void;
  private unrestrictSecurity!: (security: AuctionSecurity) => void;

  private get tableColumns(): DataTableHeader[] {
    return tableColumns as DataTableHeader[];
  }

  private get noDataText(): string {
    // not finished loading yet
    if (this.loading) {
      return i18n.t('loadingEquities') as string;
    }

    // no filter and still no securities???
    if (!this.securitySearch && this.securityFilter === 'all') {
      return i18n.t('noEquitiesYet') as string;
    }

    // no security matches search string
    if (this.securitySearch) {
      return i18n.t('noEquitiesMatched', { filter: this.securitySearch }) as string;
    }

    // no securities in current filter
    if (this.securityFilter === 'inactive') {
      return i18n.t('noEquitiesDisabled') as string;
    }
    return i18n.t('noEquitiesEnabled') as string;
  }

  @Watch('securitySearch')
  protected onSearchChange(): void {
    this.pageNumber = 1;
    void this.loadAsyncData();
  }

  @Watch('securityFilter')
  protected onFilterChange(): void {
    this.pageNumber = 1;
    void this.loadAsyncData();
  }

  protected async mounted(): Promise<void> {
    try {
      await this.loadAsyncData();
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected onToggleEnabled(security: AuctionSecurity): void {
    try {
      if (security.isAuroraActive) {
        this.askConfirmation(
          'equities.enable.title',
          'equities.enable.message',
          security,
          async () => {
            await this.enableSecurity(security);

            // refresh table view
            await this.loadAsyncData();
            this.$snackbar.confirm(`${security.ticker} [${security.cusip}] has been enabled.`);
          },
          () => (security.isAuroraRestricted = false)
        );
      } else {
        this.askConfirmation(
          'equities.disable.title',
          'equities.disable.message',
          security,
          async () => {
            await this.disableSecurity(security);

            // refresh table view
            await this.loadAsyncData();
            this.$snackbar.confirm(`${security.ticker} [${security.cusip}] has been disabled.`);
          },
          () => (security.isAuroraActive = true)
        );
      }
    } catch (e) {
      this.$snackbar.error(errorString(e));
    }
  }

  protected onToggleRestricted(security: AuctionSecurity): void {
    try {
      if (security.isAuroraRestricted) {
        this.askConfirmation(
          'equities.restrict.title',
          'equities.restrict.message',
          security,
          async () => {
            await this.restrictSecurity(security);

            // refresh table view
            await this.loadAsyncData();
            this.$snackbar.confirm(
              `${security.ticker} [${security.cusip}] trading is now restricted.`
            );
          },
          () => (security.isAuroraRestricted = false)
        );
      } else {
        this.askConfirmation(
          'equities.unrestrict.title',
          'equities.unrestrict.message',
          security,
          async () => {
            await this.unrestrictSecurity(security);

            // refresh table view
            await this.loadAsyncData();
            this.$snackbar.confirm(
              `${security.ticker} [${security.cusip}] trading is now unrestricted.`
            );
          },
          () => (security.isAuroraRestricted = true)
        );
      }
    } catch (e) {
      this.$snackbar.error(errorString(e));
    }
  }

  protected askConfirmation(
    title: string,
    message: string,
    security: AuctionSecurity,
    accept: () => void,
    reject: () => void
  ): void {
    this.$dialog.ask({
      title: i18n.t(title) as string,
      color: 'warning',
      icon: 'mdi-check',
      message: i18n.t(message, { ticker: security.ticker }),
      acceptText: i18n.tc('dialogs.submitButton'),
      onAccept: () => accept(),
      rejectText: i18n.tc('dialogs.cancelButton'),
      onReject: () => reject(),
      shouldManuallyConfirm: true,
    });
  }

  protected onPageChange(pageNumber: number): void {
    this.pageNumber = pageNumber;
    void this.loadAsyncData();
  }

  protected onSortBy(sortBy: string): void {
    // data column may have different name in the sql table
    // the sql name is what the backend expects for sorting the result
    const tableColumn = tableColumns.find((c) => c.value === sortBy);
    this.sortField = tableColumn && tableColumn.sqlColumn ? tableColumn.sqlColumn : sortBy;
    void this.loadAsyncData();
  }

  protected onSortDesc(sortDesc: boolean): void {
    this.sortDesc = sortDesc;
    void this.loadAsyncData();
  }

  private async loadAsyncData(): Promise<void> {
    if (this.abortController) {
      // we want to initiate a new request, but the previous one is still pending
      // cancel to avoid stale responses arriving late (and potentially in the wrong order)
      this.abortController.abort();
    }

    this.abortController = new AbortController();
    const q = this.securitySearch ? this.securitySearch.toUpperCase() : '';
    const params = {
      filter: this.securityFilter,
      query: q,
      sort: `${this.sortDesc ? '-' : '+'}${this.sortField}`,
      page: this.pageNumber,
    };

    this.loading = true;
    try {
      const { data } = await axios.get<{
        status: string;
        equities: RawAuctionSecurity[];
        recordCount: number;
      }>(`/api/1/broker-admin/equities`, {
        params: params,
        signal: this.abortController.signal,
      });

      this.securities = data.equities.map<AuctionSecurity>(AuctionSecurity.fromData);
      this.recordCount = data.recordCount;
    } catch (err) {
      if (axios.isCancel(err)) {
        // exception thrown by this.abortController.abort()
        // just return and wait for the new response to arrive
        return;
      }

      this.$log.warn(err);
    }
    this.loading = false;
  }

  private generateActiveTooltip(item: AuctionSecurity): string {
    return [
      'Active: ' + item.isActive,
      'Enabled: ' + item.isAuroraActive,
      'Restricted: ' + item.isAuroraRestricted,
      'Active Price Datasource: ' + item.hasActivePriceDataSource,
      'DTCC Can New Loan: ' + item.dtccCanNewLoan,
      'DTCC Can Roll: ' + item.dtccCanRoll,
    ].join('\n');
  }
}
</script>

<style lang="scss" scoped>
.v-input--selection-controls {
  margin: 0;
  padding: 0;
}

.securities-intro-alert {
  .sub {
    color: #7a7a7a;
    font-size: 0.9em;
  }
}
</style>
