import { ApiService } from '@/modules/common/services/api.service';
import Vue from 'vue';
import { ApiError } from '@/utils/errors';
import { Api } from '@/modules/common/types/api';

export class CorpActionsApiService extends ApiService {
  private featureUrl = '/corpactions';

  /**
   * Install this service as a Vue Plugin
   */
  public static install(v: typeof Vue): void {
    const singleton = new this();

    if (v.prototype.$api) {
      v.prototype.$api.corpActions = singleton;
    } else {
      v.prototype.$api = {
        corpActions: singleton,
      };
    }
  }

  public async fetchActions(cusip: string): Promise<Api.CorpActions.Action[]> {
    const url = `${this.baseUrl}${this.featureUrl}/${cusip}`;

    try {
      const { data } = await this.axios.get<Api.CorpActions.Raw.ActionsResponse>(url);
      if (data.actions === null) {
        return [];
      }

      // it would be easier if the backend returned this as an array insteao of an object
      // @TODO: should we sort this somehow?
      const mappedActions = data.actions.map<Api.CorpActions.Action>((action) => {
        const fields = Object.entries(action.fields).map<Api.CorpActions.Field>(
          ([name, field]) => ({
            ...field,
            name,
            dataType: field.dataType as Api.CorpActions.DataType,
          })
        );

        return {
          ...action,
          dateInAction: new Date(action.dateInAction),
          fields,
        };
      });

      return mappedActions;
    } catch (e) {
      throw new ApiError(`${e}`);
    }
  }
}
