// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file modules/apiengine/services/loans/loans.proto (package aurora.core.apiengine.loansv1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AcceptAwaitingLoanReturnRequest, AcceptAwaitingLoanReturnResponse, BuyInLoanRequest, BuyInLoanResponse, CancelAwaitingLoanReturnRequest, CancelAwaitingLoanReturnResponse, CancelLoanRequest, CancelLoanResponse, CancelRecallLoanRequest, CancelRecallLoanResponse, CancelReturnLoanRequest, CancelReturnLoanResponse, GetRecallsRequest, GetRecallsResponse, QueryLoansHistoryRequest, QueryLoansHistoryResponse, QueryLoansRequest, QueryLoansResponse, RecallLoanRequest, RecallLoanResponse, RejectAwaitingLoanReturnRequest, RejectAwaitingLoanReturnResponse, ReturnLoanRequest, ReturnLoanResponse } from "./loans_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * Methods to change the status of currently active loans
 *
 * The caller must be either on the lending- or borrowing side of the loan.
 *
 * @generated from service aurora.core.apiengine.loansv1.LoansService
 */
export const LoansService = {
  typeName: "aurora.core.apiengine.loansv1.LoansService",
  methods: {
    /**
     * CancelLoan can be use to cancel an Open Loan
     *
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.CancelLoan
     */
    cancelLoan: {
      name: "CancelLoan",
      I: CancelLoanRequest,
      O: CancelLoanResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.RecallLoan
     */
    recallLoan: {
      name: "RecallLoan",
      I: RecallLoanRequest,
      O: RecallLoanResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.CancelRecallLoan
     */
    cancelRecallLoan: {
      name: "CancelRecallLoan",
      I: CancelRecallLoanRequest,
      O: CancelRecallLoanResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ### Buying-In Against Recalled Loans
     * Where a lender has requested a recall of a loan, but the borrower has not returned the shares before the
     * `allowed_execution_date` indicated on the Loan Recall, the lender may buy-in the shares against the borrower.
     *
     * Aurora acts as a gateway to capture the purchase price and quantity of this buy-in, allowing the lender to reclaim
     * the costs of the buy-in against the borrower, and adjusting the loan records within Aurora and downstream clearing and settlement.
     * The lender may submit the execution price and quantity of shares by submitting this message.
     *
     * **Note that buy-ins are allocated against outstanding recalls for the indicated loan on a first-in-first-out basis,
     * and therefore a `loan_recall_id` is not supported in this message.**
     *
     * The submitted buy-in details must be processed by DTC before updating outstanding loans and recall request(s)
     * within Aurora.
     *
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.BuyInLoan
     */
    buyInLoan: {
      name: "BuyInLoan",
      I: BuyInLoanRequest,
      O: BuyInLoanResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.GetRecalls
     */
    getRecalls: {
      name: "GetRecalls",
      I: GetRecallsRequest,
      O: GetRecallsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.ReturnLoan
     */
    returnLoan: {
      name: "ReturnLoan",
      I: ReturnLoanRequest,
      O: ReturnLoanResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.AcceptAwaitingLoanReturn
     */
    acceptAwaitingLoanReturn: {
      name: "AcceptAwaitingLoanReturn",
      I: AcceptAwaitingLoanReturnRequest,
      O: AcceptAwaitingLoanReturnResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.RejectAwaitingLoanReturn
     */
    rejectAwaitingLoanReturn: {
      name: "RejectAwaitingLoanReturn",
      I: RejectAwaitingLoanReturnRequest,
      O: RejectAwaitingLoanReturnResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.CancelAwaitingLoanReturn
     */
    cancelAwaitingLoanReturn: {
      name: "CancelAwaitingLoanReturn",
      I: CancelAwaitingLoanReturnRequest,
      O: CancelAwaitingLoanReturnResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.CancelReturnLoan
     */
    cancelReturnLoan: {
      name: "CancelReturnLoan",
      I: CancelReturnLoanRequest,
      O: CancelReturnLoanResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.QueryLoans
     */
    queryLoans: {
      name: "QueryLoans",
      I: QueryLoansRequest,
      O: QueryLoansResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc aurora.core.apiengine.loansv1.LoansService.QueryLoansHistory
     */
    queryLoansHistory: {
      name: "QueryLoansHistory",
      I: QueryLoansHistoryRequest,
      O: QueryLoansHistoryResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

