import { DeepPartial, Raw } from '@/modules/common/helpers/api';
import { CompanyInfo, RawCompanyInfo, RawSecurity, Security } from '@/modules/common/models';
import Decimal from 'decimal.js';

export type RawDepthOfBookResponse = Raw<
  DepthOfBookResponse,
  {
    // always specify existing raw entry types explititly
    equity: RawSecurity;
    items: RawDepthOfBookItem[];
  },
  'security' | 'data'
>;

export class DepthOfBookResponse {
  public security: Security;
  public data: DepthOfBookItem[];

  protected constructor(data: RawDepthOfBookResponse) {
    this.security = Security.fromData(data.equity);
    this.data = data.items.map<DepthOfBookItem>((item, idx) => DepthOfBookItem.fromData(item, idx));
  }

  public static fromData(data: RawDepthOfBookResponse): DepthOfBookResponse {
    return new DepthOfBookResponse(data);
  }

  public static mock(data?: DeepPartial<RawDepthOfBookResponse>): DepthOfBookResponse {
    return DepthOfBookResponse.fromData(DepthOfBookResponse.mockData(data));
  }

  public static mockData(data?: DeepPartial<RawDepthOfBookResponse>): RawDepthOfBookResponse {
    const { equity, items } = data ?? {};

    return {
      equity: Security.mockData(equity),
      items: items?.map(DepthOfBookItem.mockData) ?? [],
    };
  }
}

export type RawDepthOfBookItem = Raw<
  DepthOfBookItem,
  {
    // always specify existing raw entry types explititly
    lendOrder: RawDepthOfBookOrder | null;
    borrowOrder: RawDepthOfBookOrder | null;
  },
  'lend' | 'borrow'
>;

export class DepthOfBookItem {
  public idx: number;
  public lend: DepthOfBookOrder | null;
  public borrow: DepthOfBookOrder | null;

  protected constructor(data: RawDepthOfBookItem, idx: number) {
    this.lend = DepthOfBookOrder.fromData(data.lendOrder);
    this.borrow = DepthOfBookOrder.fromData(data.borrowOrder);
    this.idx = idx;
  }

  public static fromData(data: RawDepthOfBookItem, idx: number): DepthOfBookItem {
    return new DepthOfBookItem(data, idx);
  }

  public static mock(data: DeepPartial<RawDepthOfBookItem>, idx: number): DepthOfBookItem {
    return DepthOfBookItem.fromData(DepthOfBookItem.mockData(data), idx);
  }

  public static mockData(data?: DeepPartial<RawDepthOfBookItem>): RawDepthOfBookItem {
    const { lendOrder, borrowOrder } = data ?? {};

    return {
      lendOrder: lendOrder === null ? null : DepthOfBookOrder.mockData(lendOrder),
      borrowOrder: borrowOrder === null ? null : DepthOfBookOrder.mockData(borrowOrder),
      idx: 1,
    };
  }
}

export type RawDepthOfBookOrder = Raw<
  DepthOfBookOrder,
  {
    // always specify existing raw entry types explititly
    counterparty: RawCompanyInfo;
  }
>;

export class DepthOfBookOrder {
  public counterparty: CompanyInfo;
  public quantity: number;
  public independentAmountRate: Decimal;
  public rate: Decimal;

  protected constructor(data: RawDepthOfBookOrder) {
    this.counterparty = CompanyInfo.fromData(data.counterparty);
    this.quantity = data.quantity;
    this.independentAmountRate = new Decimal(data.independentAmountRate);
    this.rate = new Decimal(data.rate);
  }

  public static fromData(data: RawDepthOfBookOrder): DepthOfBookOrder;
  public static fromData(data: RawDepthOfBookOrder | null): DepthOfBookOrder | null;
  public static fromData(data: RawDepthOfBookOrder): null | DepthOfBookOrder {
    if (data === null) return null;
    return new DepthOfBookOrder(data);
  }

  public static mockData(data?: DeepPartial<RawDepthOfBookOrder>): RawDepthOfBookOrder {
    const { counterparty, ...rest } = data ?? {};

    return {
      quantity: 20000,
      independentAmountRate: '0.0000',
      rate: '0.2500',
      counterparty: CompanyInfo.mockData(counterparty),

      ...rest,
    };
  }
}
