<template>
  <v-card class="flex d-flex flex-column">
    <v-container class="py-0" fluid>
      <v-row class="mt-2 mb-4" no-gutters>
        <v-tabs v-model="selectedTabIndex" @change="onTabChange">
          <v-tab
            v-for="tabName in tabNames"
            :key="tabName"
            :data-test="`aggregated-loans-${tabName}-tab-name`"
            :value="tabName"
          >
            {{ tabName }}
          </v-tab>
        </v-tabs>
      </v-row>
    </v-container>

    <open-loans-table-aggregate
      v-if="!isTabChanging"
      :grouping-type="tab"
      :page="page"
      :page-size="pageSize"
      :query-data="queryData"
      :sort="sort || defaultSort"
      v-on="$listeners"
      @open-dialog="dialog = $event"
      @ready="onReady"
    />

    <mass-modify-loans-dialog
      v-if="dialog"
      :action="dialog.action"
      :counterparty="dialog.counterparty"
      :grouping-type="tab"
      :security="dialog.security"
      @close-modal="dialog = null"
      @success="resetSelection"
    />
  </v-card>
</template>

<script lang="ts">
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import MassModifyLoansDialog from '@/modules/open-loans/components/MassModifyLoansDialog.vue';
import OpenLoansTableAggregate from '@/modules/open-loans/components/OpenLoansTableAggregate.vue';
import { MultipleLoanAction } from '@/modules/open-loans/types/open-loans';
import { CompanyInfo } from '@/modules/common/models';
import { SocketEvents } from '@/store/store';
import { AggregatedLoans, AggregatedOpenLoansParams } from '@/utils/api/loans';
import { SortModelItem } from 'ag-grid-community';
import { LoadSuccessParams } from 'ag-grid-enterprise';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

type TabName = 'counterparty' | 'security';

interface OpenDialog {
  action: MultipleLoanAction;
  security?: { cusip: string; ticker: string };
  counterparty?: CompanyInfo;
}

function stubFn() {
  return;
}

@Component({
  props: {
    tab: {
      type: String as PropType<TabName>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    sort: {
      type: Object as PropType<SortModelItem>,
      required: false, // this.defaultSort used as fallback
    },
  },
  components: {
    OpenLoansTableAggregate,
    SnapshotManager,
    MassModifyLoansDialog,
  },
  computed: {
    ...mapState(['socketEvents']),
  },
})
export default class OpenLoansListWithAggregate extends Vue {
  protected readonly tab!: TabName;
  protected readonly page!: number;
  protected readonly pageSize!: number;
  protected readonly sort?: SortModelItem;

  protected tabNames: TabName[] = ['security', 'counterparty'];
  protected dialog: OpenDialog | null = null;
  protected isTabChanging = false;

  // store state
  protected socketEvents!: SocketEvents;

  protected aggregatedLoans: AggregatedLoans = { items: [], total: 0 };
  protected tableRefresh: (config: { purge: boolean }) => void = stubFn;
  protected resetSelection: () => void = stubFn;

  protected get defaultSort(): SortModelItem {
    return { colId: this.tab === 'security' ? 'ticker' : 'counterpartyDisplay', sort: 'asc' };
  }

  protected get selectedTabIndex(): number {
    return this.tabNames.findIndex((t) => t === this.tab);
  }

  protected set selectedTabIndex(index: number) {
    this.$emit('update:tab', this.tabNames[index]);
  }

  @Watch('socketEvents.openLoans.lenderLoan')
  @Watch('socketEvents.openLoans.borrowerLoan')
  protected onSocketEvents(): void {
    // table is expected to remain more or less the same,
    // no need to purge the cache, just refresh and update the rows
    this.tableRefresh({ purge: false });
  }

  protected onReady(config: {
    refresh: (config: { purge: boolean }) => void;
    resetSelection: () => void;
  }): void {
    this.tableRefresh = config.refresh;
    this.resetSelection = config.resetSelection;
  }

  protected async queryData(config: {
    page: number;
    pageSize: number;
    sort: SortModelItem;
    signal: AbortSignal;
  }): Promise<LoadSuccessParams | undefined> {
    try {
      const params: AggregatedOpenLoansParams = {
        group: this.tab,
        sort: `${config.sort.sort === 'desc' ? '-' : ''}${config.sort.colId}`,
        pagination: {
          page: config.page,
          limit: config.pageSize,
        },
      };
      const res = await this.$api.openLoans.fetchAggregatedOpenLoans(params, config.signal);
      if (!res) return;

      return { rowData: res.items, rowCount: res.total };
    } catch (e) {
      this.$log.warn(e);
    }
  }

  protected async onTabChange(): Promise<void> {
    // this unmounts the ag-grid table
    this.isTabChanging = true;
    // we must let the template use its defaults when changing tabs,
    // so we set "sort" to empty and page back to 1
    this.$emit('update:sort', undefined);
    this.$emit('update:page', 1);
    await this.$nextTick();
    this.isTabChanging = false;
  }
}
</script>

<style lang="scss" scoped></style>
