<template>
  <snapshot-manager
    :drivers="['sessionStorage']"
    :get-state-mutators="getStateMutators"
    :snapshot="snapshot"
    storage-key="snapshot-orders-explorer"
  >
    <div class="d-flex fill-height full-width flex-grow-1">
      <div
        ref="leftPanelRef"
        :class="['d-flex flex-column fill-height pa-0 col']"
        :style="{ width: sizes.leftPanelWidth + 'px' }"
      >
        <dashboard-panel no-collapse title="orders">
          <marketplace-orders-list
            @action="
              confirmationSnackbarOptions = {
                ...$event,
                showViewButton: detailOrderRef !== $event.orderRef,
              }
            "
            @create-order="openCreateDialog($event)"
            @edit-order="openEditDialog($event)"
            @view-order="detailOrderRef = $event"
          />
          <marketplace-action-confirmation-snackbar
            v-if="confirmationSnackbarOptions"
            :options="confirmationSnackbarOptions"
            @close="confirmationSnackbarOptions = null"
            @view-order="detailOrderRef = confirmationSnackbarOptions.orderRef"
          />
        </dashboard-panel>
      </div>
      <div :class="['resize-handle-w', { 'd-none': !detailOrderRef }]" @mousedown="startResizeW" />
      <div
        v-if="detailOrderRef"
        ref="rightPanelRef"
        class="d-flex flex-column fill-height pa-0 flex-1"
        :style="{ width: sizes.rightPanelWidth + 'px' }"
      >
        <dashboard-panel
          no-collapse
          :show-close-icon="true"
          title="Order Details"
          @close="detailOrderRef = null"
        >
          <marketplace-order-details-card
            :order-ref="detailOrderRef"
            :should-retry-fetch="true"
            @action="confirmationSnackbarOptions = $event"
            @close-modal="detailOrderRef = null"
            @edit-order="openEditDialog($event)"
            @open-loan-details="detailLoanId = $event"
          />
        </dashboard-panel>
      </div>
      <loan-details-dialog
        v-if="detailLoanId"
        :as-broker="false"
        :loan-id="detailLoanId"
        :should-retry-fetch="true"
        @close-modal="detailLoanId = null"
      />
      <marketplace-order-form-dialog
        v-if="newOrderSide"
        :new-order-side="newOrderSide"
        @action="confirmationSnackbarOptions = { ...$event, showViewButton: true }"
        @close-modal="newOrderSide = null"
      />
      <marketplace-order-form-dialog
        v-if="editOrder"
        :order="editOrder"
        @action="
          confirmationSnackbarOptions = {
            ...$event,
            showViewButton: detailOrderRef !== $event.orderRef,
          }
        "
        @close-modal="editOrder = null"
      />
    </div>
  </snapshot-manager>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardPanel from '@/modules/dashboard/components/DashboardPanel.vue';
import MarketplaceOrdersList from '@/modules/marketplace/components/MarketplaceOrdersList.vue';
import MarketplaceActionConfirmationSnackbar from '@/modules/marketplace/components/MarketplaceActionConfirmationSnackbar.vue';
import MarketplaceOrderDetailsCard from '@/modules/marketplace/components/MarketplaceOrderDetailsCard.vue';
import MarketplaceOrderFormDialog from '@/modules/marketplace/components/MarketplaceOrderFormDialog.vue';
import { ConfirmationSnackbarOptions } from '@/modules/marketplace/types/marketplace';
import LoanDetailsDialog from '@/modules/open-loans/components/LoanDetailsDialog.vue';
import SnapshotManager from '@/modules/common/components/SnapshotManager.vue';
import { OmsOrder } from '@/modules/marketplace/models';

const DEFAULT_SIZES = {
  leftPanelWidth: 240,
  rightPanelWidth: 840,
};

const MIN_COL_WIDTH = 450;

@Component({
  provide() {
    return {
      gridStateKey: 'MarketplaceOrders',
    };
  },
  components: {
    DashboardPanel,
    MarketplaceOrdersList,
    MarketplaceActionConfirmationSnackbar,
    MarketplaceOrderDetailsCard,
    MarketplaceOrderFormDialog,
    LoanDetailsDialog,
    SnapshotManager,
  },
})
export default class MarketplaceOrdersExplorer extends Vue {
  public $refs!: {
    leftPanelRef: HTMLElement;
    rightPanelRef: HTMLElement;
  };
  protected detailOrderRef: string | null = null;
  protected confirmationSnackbarOptions: ConfirmationSnackbarOptions | null = null;
  protected editOrder: OmsOrder | null = null;
  protected newOrderSide: 'BORROWER' | 'LENDER' | null = null;
  protected detailLoanId: string | null = null;

  protected startX = 0;
  protected sizes = { ...DEFAULT_SIZES };
  // for performance resons (we don't want to JSON.stringify every time the mouse moves)
  // we have a separate object that we manualy update when resizing stops
  // (the snapshot getter observes this var)
  protected storedSizes = { ...DEFAULT_SIZES };

  // detect if the user has moved the window to a different screen
  protected currentScreenX: number = window.screenX;

  protected get snapshot(): Record<string, string> {
    return {
      sizes: JSON.stringify(this.storedSizes),
      payload: JSON.stringify({
        detailOrderRef: this.detailOrderRef,
      }),
    };
  }

  protected mounted(): void {
    // because the snapshot getter is triggered before mounted,
    // it overrides what's currently in sessionStorage with the default values
    // save it again with what we initially got from sessionStorage before mount
    // (i.e retrigger the getter with the correct values)
    this.storedSizes = { ...this.sizes };
    window.addEventListener('resize', this.handleScreenChange);
  }

  protected beforeDestroy(): void {
    window.removeEventListener('resize', this.handleScreenChange);
  }

  protected getStateMutators(): ReturnType<SnapshotManager['getStateMutators']> {
    return {
      sizes: (value) => {
        const storedSizes = JSON.parse(value);
        this.sizes = storedSizes;
      },
      payload: (value) => {
        const payload = JSON.parse(value);
        for (const key in payload) {
          this[key] = payload[key];
        }
      },
    };
  }

  protected openCreateDialog(side: 'BORROWER' | 'LENDER'): void {
    this.newOrderSide = side;
    this.editOrder = null;
  }

  protected openEditDialog(order: OmsOrder): void {
    this.newOrderSide = null;
    this.editOrder = order;
  }

  protected startResizeW(event: MouseEvent): void {
    document.body.style.cursor = 'col-resize';
    this.startX = event.clientX;

    const onMouseMove = (e: MouseEvent) => {
      const deltaX = e.clientX - this.startX;

      // re-assign real width
      this.sizes.leftPanelWidth = this.$refs.leftPanelRef.clientWidth;
      this.sizes.rightPanelWidth = this.$refs.rightPanelRef.clientWidth;

      const shouldStopResizing =
        this.sizes.rightPanelWidth - deltaX >= MIN_COL_WIDTH &&
        this.sizes.leftPanelWidth + deltaX >= MIN_COL_WIDTH;

      if (shouldStopResizing) {
        this.sizes.leftPanelWidth += deltaX;
        this.sizes.rightPanelWidth -= deltaX;
        this.startX = e.clientX;
      }
    };

    const onMouseUp = () => {
      document.body.style.cursor = 'auto';
      this.storedSizes = { ...this.sizes };
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  }

  protected handleScreenChange(): void {
    if (window.screenX !== this.currentScreenX) {
      // because our users might have multiple monitors
      // we need to reset the panels widths and heights to inital values
      this.sizes = { ...DEFAULT_SIZES };
      this.storedSizes = { ...DEFAULT_SIZES };
    }
  }
}
</script>

<style lang="scss">
.resize-handle-w {
  user-select: none;
  width: 5px;
  border: 2px solid transparent;
  cursor: col-resize;
  user-select: none;
  height: calc(100% - 16px);
  margin-top: 8px;
  &:hover {
    background: #ebeced;
    opacity: 0.1;
  }
}
</style>
