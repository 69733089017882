/* eslint-disable vue/one-component-per-file */

import { Decimal } from 'decimal.js';
import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { VChip } from 'vuetify/lib';
import { alignCenter, alignLeft, alignRight, comparator, component } from './utils';
import { getStatus, timeInForceAbbr } from '@/modules/marketplace/helpers/marketplace';
import MarketplaceOrdersTableActions from '@/modules/marketplace/components/MarketplaceOrdersTableActions.vue';
import * as cols from '@/modules/common/components/ag-table/columns/common';
import { h } from 'vue';
import RateOutput from '@/modules/common/components/format-rate/RateOutput.vue';
export { checkbox } from './common';
import {
  OmsOrderStatus,
  OmsOrderType,
  OmsTimeInForceType,
  RoutingStatus,
} from '@/connect/gen/consts/omsconsts_pb';
import { proto3 } from '@bufbuild/protobuf';
import { Side } from '@/connect/gen/consts/commonconsts_pb';
import { SettlementType } from '@/connect/gen/consts/settlementconsts_pb';
import { Order } from '@/connect/gen/modules/apiengine/services/oms/oms_pb';
import { useStoreSecurities } from '@/store/store-securities';
import { useStoreCompanies } from '@/store/store-companies';
import { OrderStatus, OrderType } from '@/modules/marketplace/types/marketplace';
import { OmsOrder } from '@/modules/marketplace/models';
import formatPrettyNumber from '@/modules/common/components/pretty-number/formatPrettyNumber';

const statusAdapter = component<{
  order: Order;
  viewOrder: (orderRef: string) => void;
  label: string;
  color: string;
}>(
  (props) => () =>
    h(
      VChip,
      {
        style: 'width: 108px',
        class: `justify-center ${
          props.order.orderType === OmsOrderType.IOI &&
          props.order.orderStatus === OmsOrderStatus.OPEN
            ? 'text-uppercase'
            : 'text-lowercase'
        }`,
        props: { color: props.color, small: true },
        on: { click: () => props.viewOrder(props.order.orderRef) },
      },
      props.label
    )
);
export function status({ viewOrder }: { viewOrder: (orderRef: string) => void }): ColDef<Order> {
  return {
    field: 'orderStatus',
    colId: 'orderStatus',
    headerName: 'Status',
    cellRendererSelector: (params) => {
      return statusAdapter({
        order: params.data!,
        viewOrder,
        ...getStatus({
          status: proto3.getEnumType(OmsOrderStatus).values.find((v) => v.no === params.value)
            ?.name as OrderStatus,
          orderType: proto3
            .getEnumType(OmsOrderType)
            .values.find((v) => v.no === params.data?.orderType)?.name as OrderType,
          filled: Number(params.data?.totalExecQty),
        } as unknown as OmsOrder),
      });
    },
    resizable: false,
    pinned: 'left',
    minWidth: 108,
    width: 108,
    ...alignCenter(),
  };
}

export function side(): ColDef<Order> {
  return {
    field: 'side',
    colId: 'side',
    headerName: 'Side',
    cellRendererSelector: (params) =>
      cols.formatSideAdapter({
        side: params.value === Side.BORROWER ? 'BORROWER' : 'LENDER',
      }),
    ...alignLeft(),
  };
}

export function cusip(): ColDef<Order> {
  return {
    field: 'cusip',
    colId: 'cusip',
    headerName: 'CUSIP',
    ...alignLeft(),
  };
}

export function ticker(): ColDef<Order> {
  return {
    field: 'cusip',
    colId: 'security.ticker',
    headerName: 'Ticker',
    valueFormatter: (params) => useStoreSecurities().getSecurity(params.value)?.ticker ?? '–',
    ...alignLeft(),
  };
}

export function active(): ColDef<Order> {
  return {
    field: 'routingStatus',
    colId: 'routingStatus',
    headerName: 'Active',
    valueFormatter: (params) => {
      return params.value === RoutingStatus.ROUTED
        ? 'Active'
        : params.value === RoutingStatus.PENDING
          ? 'Pending'
          : '–';
    },
    ...alignLeft(),
  };
}

export function company(): ColDef<Order> {
  return {
    field: 'companyId',
    colId: 'companyId',
    headerName: 'Company',
    valueFormatter: (params) => {
      const cp = useStoreCompanies().getCompany(params.value);
      return `${cp?.name} ${cp?.displayBoxId != null ? ' (' + cp.displayBoxId + ')' : ''}`;
    },
    ...alignLeft(),
  };
}

export function rate(): ColDef<Order> {
  return {
    field: 'rate',
    colId: 'rate',
    headerName: 'Rate Limit',
    cellRendererSelector: (params) =>
      cols.rateAdapter({
        rate: params.value,
        rateModifier: undefined,
        precision: undefined,
      }),
    comparator: comparator.decimal,
    cellDataType: 'text',
    ...alignRight({ hasPostfix: true }),
  };
}

export const openQuantity = (): ColDef<Order> =>
  cols.quantity({ field: 'openQuantity', headerName: 'Open Qty' });

export const execQty = (): ColDef<Order> => {
  return {
    ...cols.quantity({ field: 'totalExecQty', colId: 'totalExecQty', headerName: 'Exec Qty' }),
    valueFormatter: (params: ValueFormatterParams<Order, bigint>) =>
      !params.value ? '–' : formatPrettyNumber(params.value),
  };
};

const avgExecutionRateAdapter = component<{
  rate: number | string | Decimal | null;
}>((props) => () => (props.rate === null ? h('span', '–') : h(RateOutput, { props })));

export function avgExecRate(): ColDef<Order> {
  return {
    field: 'avgExecRate',
    colId: 'avgExecRate',
    headerName: 'Avg Exec Rate',
    cellRendererSelector: (params) =>
      avgExecutionRateAdapter({
        rate: params.value,
      }),
    comparator: (a, b) => comparator.decimal(a?.avgExecutionRate, b?.avgExecutionRate),
    cellDataType: 'text',
    ...alignRight(),
  };
}

export const totalQuantity = (): ColDef<Order> =>
  cols.quantity({ field: 'quantity', headerName: 'Total Qty' });

export const createTime = (): ColDef<Order> =>
  cols.timestamp({ field: 'createdAt', headerName: 'Create Time' });

export const updateTime = (): ColDef<Order> =>
  cols.timestamp({ field: 'updatedAt', headerName: 'Update Time' });

const orderTypeAdapter = component<{ orderType: string }>(
  (props) => () =>
    h(
      'span',
      { class: 'text-capitalize' },
      props.orderType !== 'IOI' ? props.orderType.toLowerCase() : props.orderType
    )
);

export function orderType(): ColDef<Order> {
  return {
    field: 'orderType',
    colId: 'orderType',
    headerName: 'Type',
    cellRendererSelector: (params) => {
      const type = proto3.getEnumType(OmsOrderType).values.find((v) => v.no === params.value)
        ?.name as string;
      return orderTypeAdapter({
        orderType: type,
      });
    },
    ...alignLeft(),
  };
}

export function timeInForce(): ColDef<Order> {
  return {
    field: 'timeInForceType',
    colId: 'timeInForceType',
    headerName: 'Time in Force',
    valueFormatter: (params) => {
      const type = proto3.getEnumType(OmsTimeInForceType).values.find((v) => v.no === params.value)
        ?.name as string;
      return timeInForceAbbr(type);
    },
    ...alignLeft(),
  };
}

export function minQuantity(): ColDef<Order> {
  return {
    field: 'minQuantity',
    colId: 'minQuantity',
    headerName: 'Min. Quantity',
    valueFormatter: (params: ValueFormatterParams<Order, bigint>) =>
      params.value === 1n || !params.value ? '–' : formatPrettyNumber(params.value),
    ...alignRight(),
  };
}
export function counterparties(): ColDef<Order> {
  return {
    field: 'counterpartyIds',
    colId: 'counterpartyIds',
    headerName: 'Counterparties',
    valueFormatter: (params) =>
      params.value.length
        ? params.value.map((id) => useStoreCompanies().getCompany(id)?.displayBoxId).join(', ')
        : 'Any',
    ...alignLeft(),
  };
}

export function settlementType(): ColDef<Order> {
  return {
    field: 'settlementType',
    colId: 'settlementType',
    headerName: 'Sett Type',
    cellRendererSelector: (params) => {
      const settlType = proto3.getEnumType(SettlementType).values.find((v) => v.no === params.value)
        ?.name as string;
      return cols.settlementTypeAdapter({
        settlType,
      });
    },
    ...alignLeft(),
  };
}

export function orderRef(): ColDef<Order> {
  return {
    field: 'orderRef',
    colId: 'orderRef',
    headerName: 'OrderRef',
    ...alignLeft(),
  };
}

const actionsAdapter = component<{
  order: Order;
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: Order) => void;
  actions?: string[];
}>((props) => () => h(MarketplaceOrdersTableActions, { props }));

export function actions({
  viewOrder,
  editOrder,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  actions = ['view', 'edit', 'route', 'unroute', 'cancel'],
}: {
  viewOrder?: (orderRef: string) => void;
  editOrder?: (order: Order) => void;
  actions?: string[];
}): ColDef<Order> {
  return {
    colId: 'actions',
    headerName: 'Actions',
    cellRendererSelector: (params) =>
      actionsAdapter({
        order: params.data!,
        viewOrder,
        editOrder,
        actions,
      }),
    pinned: 'right',
    lockVisible: true,
    width: 150,
    maxWidth: 150,
    suppressColumnsToolPanel: true,
    ...alignCenter(),
  };
}
