import { OpenLoanItem } from '@/modules/common/models';

export type NewQuantities = Record<number, number>;

// sort by the provided function and distribute the quantity in sequence
export function distributeSorted(loans: OpenLoanItem[], totalQuantity: number): NewQuantities {
  const quantities: NewQuantities = {};

  for (const item of loans) {
    if (totalQuantity > 0) {
      const quantity = Math.min(availableToUpdate(item), totalQuantity);
      totalQuantity -= quantity;
      quantities[item.id] = quantity;
    } else {
      quantities[item.id] = 0;
    }
  }

  if (totalQuantity > 0) {
    // overflow is distributed to the first loan
    quantities[loans[0].id] += totalQuantity;
  }

  return quantities;
}

// try to distribute the quantity in lots of 100s
export function distributeEqualShares(loans: OpenLoanItem[], totalQuantity: number): NewQuantities {
  const quantities = loans.reduce<NewQuantities>((acc, loan) => {
    acc[loan.id] = 0;
    return acc;
  }, {});

  while (totalQuantity > 0) {
    let hasDistributedThisCycle = false;

    for (const loan of loans) {
      if (quantities[loan.id] < availableToUpdate(loan)) {
        const possibleShare = Math.min(
          100,
          totalQuantity,
          availableToUpdate(loan) - quantities[loan.id]
        );

        quantities[loan.id] += possibleShare;
        totalQuantity -= possibleShare;
        hasDistributedThisCycle = true;
      }

      if (totalQuantity === 0) {
        break;
      }
    }

    if (!hasDistributedThisCycle) {
      // overflow is distributed to the first loan
      quantities[loans[0].id] += totalQuantity;
      totalQuantity = 0;
    }
  }

  return quantities;
}

export function availableToUpdate(loan: OpenLoanItem): number {
  if (loan.side === 'LENDER') {
    return loan.openQuantityToRecall;
  } else {
    return loan.openQuantityToReturn;
  }
}

export function availableQuantity(openLoans: OpenLoanItem[]): number {
  return openLoans.reduce((total, loan) => total + availableToUpdate(loan), 0);
}

export function isTotalQuantityWithinAvailable(
  quantity: number | null,
  openLoans: OpenLoanItem[]
): boolean {
  if (quantity === null) {
    return true;
  }
  return availableQuantity(openLoans) >= quantity;
}

export function areSingleQuantitiesWithinAvailable(
  newQuantities: NewQuantities,
  openLoans: OpenLoanItem[]
): boolean {
  for (const loan of openLoans) {
    if (newQuantities[loan.id] > availableToUpdate(loan)) {
      return false;
    }
  }
  return true;
}
